
                  @use 'sass:meta';
                  @import '~@/css/variables.scss';
                







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.home-page {
  margin-bottom: 24px;
  color: $sma-dark-gray;
}

section {
  padding: 48px 0;
  border-bottom: 1px solid $sma-blue;

  &:last-child {
    border-bottom: 0;
  }
}

h1,
h2,
h3,
h4 {
  color: inherit;
  font-weight: inherit;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $breakpoint-lg) {
    max-width: $grid-max-width-md !important;
  }
}

#hero {
  position: relative;
  padding: 0;
  border: 0;

  .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .text {
    color: $sma-near-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (min-width: $breakpoint-md) {
      padding-right: 16px;
      text-align: left;
    }

    h2 {
      font-size: 50px;
      font-weight: 500;
    }

    .body {
      margin-top: 16px;
      font-size: 20px;
      font-weight: 200;
    }
  }

  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;

    @media (min-width: $breakpoint-md) {
      padding-left: 16px;
      margin-top: 0;
    }

    img {
      width: 100%;
    }
  }

  .video {
    margin-top: 32px;

    @media (min-width: $breakpoint-md) {
      margin-top: 0;
    }
  }

  .video-container {
    position: relative;
    padding-top: calc((9 / 16) * 100%);
    width: 100%;

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .waves {
    position: relative;
    bottom: -8px;
  }
}

#callouts {
  border-bottom: 1px solid $sma-blue;
  padding-bottom: 32px;

  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.callout-container {
  margin-bottom: 24px;
}

.callout {
  &:not(:last-child) {
    padding-right: 40px;
  }

  .icon {
    display: flex;
    justify-content: center;
    font-size: 64px;
    margin-bottom: 24px;
  }

  .header {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 12px;
  }

  .body {
    font-size: 16px;
    text-align: center;
    line-height: 28px;
  }
}

#map {
  padding-top: 32px;
  padding-bottom: 32px;

  .sponsors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (min-width: $breakpoint-md) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  .sponsor {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    @media (min-width: $breakpoint-md) {
      .sponsor {
        width: auto;
        margin-bottom: 0;
      }
    }
  }

  h2 {
    text-align: center;
    font-size: 52px;
    font-weight: 500;
    margin-top: 48px;

    @media (min-width: $breakpoint-md) {
      width: 80%;
    }
  }

  .sub-h2 {
    text-align: center;
    font-size: 26px;
    line-height: 1;
    font-weight: 500;
    margin-top: 24px;
    margin-bottom: 0;

    @media (min-width: $breakpoint-md) {
      width: 80%;
    }
  }

  .map-container {
    margin-top: 48px;
    position: relative;
    width: 100%;
  }

  .map {
    position: relative;
    padding-bottom: calc(100% * 11 / 16);
  }

  .legend {
    margin-top: 8px;

    @media (min-width: $breakpoint-md) {
      margin-top: 0;
      flex-direction: column;
      position: absolute;
      bottom: 32px;
      left: 16px;
      padding: 16px;
      background-color: $sma-white;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    }

    .header {
      font-weight: 700;
    }

    .content {
      display: flex;
      margin-top: 8px;

      @media (min-width: $breakpoint-md) {
        flex-direction: column;
      }

      .type {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 0;
          margin-right: 8px;

          @media (min-width: $breakpoint-md) {
            margin-bottom: 8px;
            margin-right: 0;
          }
        }

        img {
          height: 32px;
          width: 32px;
          margin-right: 8px;
        }
      }
    }
  }

  h3 {
    margin-top: 48px;
    font-size: 38px;
    font-weight: 700;
  }

  .stats {
    margin-top: 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 48px;

    .num {
      color: $sma-black;
      font-weight: 900;
    }

    @media (min-width: $breakpoint-md) {
      width: 65%;
      font-size: 32px;
    }
  }

  & > .btn {
    margin-top: 48px;
  }

  .col {
    position: relative;
    height: 100%;
    background-color: $sma-near-white;
  }

  .form {
    position: absolute;
    top: 1rem;
    left: 1.5rem;
    right: 1.5rem;
    display: flex;
    background-color: $sma-white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    padding: 0.5rem 1rem;
    font-size: 0.875rem;

    @media only screen and (max-width: $breakpoint-lg) {
      flex-wrap: wrap;
    }

    .col {
      background: transparent;
    }
  }

  .close-btn {
    cursor: pointer;
    position: absolute;
    top: 0.1em;
    right: 0.1em;
    color: $sma-gray;
    font-size: 1.5em;
    border: 0;
    background-color: transparent;
  }

  .view-all-btn {
    position: absolute;
    bottom: 1rem;
    left: calc(50% - 100px);
    width: 200px;

    @media only screen and (max-width: $breakpoint-sm) {
      top: 13.5em;
      left: 3em;
    }
  }

  .home-btn {
    position: absolute;
    bottom: 1rem;
    left: 0.5rem;
  }

  .zoom-btns {
    position: absolute;
    bottom: 1rem;
    right: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .show-search-btn {
    position: absolute;
    top: 0;
    left: 0.5rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }

  .label {
    color: $sma-gray;
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
  }

  .input {
    border: 1px solid $sma-light-gray;
    border-radius: 0.25rem;
    padding: 0.5rem;
    width: 100%;

    @media only screen and (max-width: $breakpoint-sm) {
      margin: 0.5rem 0;
    }

    &.address {
      width: 100%;
      background: #fff;
      border-radius: 10em;
      font-size: 1.5em;
      padding: 0.2em 0.5em;
    }

    &.model {
      width: 8rem;

      @media only screen and (max-width: $breakpoint-lg) {
        width: 100%;
      }
    }
  }

  .amount {
    text-align: center;
  }

  .results {
    flex: 1;
    padding: 1rem;
    height: 100%;
    overflow: auto;
  }

  .stats {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column;

      div:first-child {
        margin-bottom: 1rem;
      }
    }
  }

  .dropdown {
    display: flex;
    align-items: center;
    color: $sma-gray;
    font-size: 0.875rem;
    border: 1px solid $sma-gray;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;

    .text {
      margin-right: 1rem;
    }
  }

  .share {
    margin: 1rem 0;
  }

  .no-shares {
    text-align: center;
  }

  .col-flex {
    @media only screen and (max-width: $breakpoint-lg) {
      height: auto;
    }
  }

  .categories-btn {
    position: relative;

    .categories {
      position: absolute;
      background-color: $sma-white;
      top: 100%;
      padding: 1rem;
      z-index: 999;

      .drop {
        display: flex;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        & input {
          width: auto;
          margin: 0 4px 0 -4px;
        }
      }
    }
  }

  .search-types {
    @media (min-width: $breakpoint-lg) {
      height: 58px;
    }

    & > .col {
      align-items: center;
      height: auto;

      @media (min-width: $breakpoint-lg) {
        align-items: start;
        margin: 0;

        /* margin-top: 4px; */
      }

      & > * {
        width: fit-content;
      }
    }
  }
}

.ad {
  img {
    width: 100%;
  }
}

#blog {
  h2 {
    font-size: 38px;
    font-weight: 700;
  }

  .previews {
    margin-top: 2rem;

    > * + * {
      margin-top: 2rem;
    }
  }
}

.list-section {
  .img-container {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    @media (min-width: $breakpoint-md) {
      margin-bottom: 0;
      padding-right: 48px;
    }

    > img {
      width: 100%;
    }
  }

  .checks {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-md) {
      flex-direction: inherit;
    }
  }

  h3 {
    font-size: 38px;
    font-weight: 700;
  }

  .body {
    font-size: 18px;
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin-top: 32px;
  }

  li {
    display: flex;

    &:not(:first-child) {
      margin-top: 32px;
    }

    h4 {
      font-size: 18px;
      font-weight: 500;
    }

    .body {
      font-size: 16px;
    }
  }

  .bullet {
    font-size: 18px;
    background-color: $sma-light-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2em;
    height: 2em;
    border-radius: 99999px;
    flex: none;
    margin-right: 8px;
  }

  .check {
    &:not(:first-child) {
      margin-left: 0;

      @media (min-width: $breakpoint-md) {
        margin-left: 16px;
      }
    }

    .icon {
      color: #22bc66;
    }
  }

  .btn {
    margin-top: 32px;
  }

  &.reverse {
    .row {
      flex-direction: row-reverse;
    }

    .img-container {
      @media (min-width: $breakpoint-md) {
        padding-left: 48px;
      }
    }
  }
}

#recent-shares,
#nearest-shares {
  .header {
    text-align: center;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .subheading {
    margin-top: 8px;
  }

  .shares {
    margin-top: 24px;
  }
}

#announcements {
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 24px;
  }

  img { width: 100%; }
}

#new-features {
  h2 {
    font-size: 38px;
    font-weight: 700;
  }

  .features {
    justify-content: center;
    margin-top: 48px;
  }

  .feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h3 {
      margin-top: 16px;
      color: $sma-blue;
      font-size: 18px;
      font-weight: 500;
    }

    .body {
      margin-top: 1rem;
      text-align: center;
    }

    .link {
      margin-top: 16px;
      color: $sma-blue;
      font-weight: 500;
    }
  }
}

#pricing {
  h2 {
    font-size: 38px;
    font-weight: 700;
  }

  .subheading {
    margin-top: 8px;
    font-size: 20px;
    font-weight: 400;
  }

  .table-container {
    @media (max-width: $breakpoint-md) {
      width: 100%;
      overflow-x: auto;
      display: none;
    }
  }

  .mobile-table-container {
    display: none;

    @media (max-width: $breakpoint-md) {
      display: block;
      width: 100%;
    }

    table {
      width: 100%;
      table-layout: auto;
      overflow: hidden;
    }
  }

  table {
    margin-top: 48px;
    border-collapse: collapse;

    @media (max-width: $breakpoint-md) {
      width: 600px;
      table-layout: fixed;
    }

    thead {
      tr:last-child th {
        padding-bottom: 16px;
      }

      th {
        font-weight: 400;
        padding-bottom: 8px;
      }

      .header {
        font-size: 16px;
        font-weight: 500;
      }

      .body {
        font-size: 16px;
      }

      .price {
        font-size: 28px;
      }

      .sub {
        font-size: 12px;
      }
    }

    tbody {
      tr {
        border-top: 1px solid $sma-light-gray;
        border-bottom: 1px solid $sma-light-gray;
      }

      tr:last-child {
        border-bottom: 0;

        th,
        td {
          padding: 20px 0;
        }
      }

      th,
      td {
        font-size: 14px;
        font-weight: 200;
        padding: 12px 0;
      }

      td {
        text-align: center;
      }
    }
  }

  .tooltip-btn {
    background-color: transparent;
    border: solid 1px $sma-dark-gray;
    border-radius: 999px;
    cursor: pointer;
    width: 1.5em;
    height: 1.5em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;

    * {
      font-size: 0.6em;
    }
  }

  $tooltip-fade-dur: 300ms;

  .tooltip-btn .tooltip {
    width: 300px;
    background-color: $sma-blue;
    color: $sma-white;
    text-align: center;
    position: absolute;
    border-radius: 5px;
    font-size: 0.8rem;
    padding: 10px;
    z-index: 1;
    top: -92%;
    left: 150%;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear $tooltip-fade-dur, opacity $tooltip-fade-dur;
  }

  .tooltip-btn:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity $tooltip-fade-dur;
  }

  .tooltip-btn .tooltip::after {
    content: " ";
    position: absolute;
    top: 1.5rem;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent $sma-blue transparent transparent;
  }
}

#testimonials {
  h2 {
    font-size: 38px;
    font-weight: 700;
  }

  .subheading {
    margin-top: 8px;
    font-weight: 20px;
  }

  .testimonial {
    margin-top: 48px;

    .rating {
      display: flex;
      justify-content: center;
      font-size: 16px;

      .stars {

      }

      .star {
        color: #f6ba10;
      }

      .numeric {
        margin-left: 8px;
      }
    }

    .body {
      margin-top: 16px;
      font-size: 20px;
      line-height: 32px;
      text-align: center;
    }

    .author {
      margin-top: 16px;
      font-size: 16px;
      text-align: center;
    }
  }
}
